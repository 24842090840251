// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-desinfecteren-js": () => import("./../../src/pages/desinfecteren.js" /* webpackChunkName: "component---src-pages-desinfecteren-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-fancy-form-js": () => import("./../../src/pages/fancy-form.js" /* webpackChunkName: "component---src-pages-fancy-form-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offerte-js": () => import("./../../src/pages/offerte.js" /* webpackChunkName: "component---src-pages-offerte-js" */),
  "component---src-pages-overons-js": () => import("./../../src/pages/overons.js" /* webpackChunkName: "component---src-pages-overons-js" */),
  "component---src-pages-particulier-js": () => import("./../../src/pages/particulier.js" /* webpackChunkName: "component---src-pages-particulier-js" */),
  "component---src-pages-privacybeleid-js": () => import("./../../src/pages/privacybeleid.js" /* webpackChunkName: "component---src-pages-privacybeleid-js" */),
  "component---src-pages-ramenwassen-js": () => import("./../../src/pages/ramenwassen.js" /* webpackChunkName: "component---src-pages-ramenwassen-js" */),
  "component---src-pages-zakelijk-js": () => import("./../../src/pages/zakelijk.js" /* webpackChunkName: "component---src-pages-zakelijk-js" */)
}

